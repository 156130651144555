import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import CalendlyEmbed from "../components/CalendalyEmbed"
import BlueHeader from "../components/BlueHeader"
import Footer from "../components/Footer";
import Workshop from "../components/Workshop";
import WorkshopForm from "../components/WorkshopForm";

const RegisterPage = () => {
    const [activeTab, setActiveTab] = useState("signup");

    return (<Layout>
        <SEO title="Home" />
        <div className="flex flex-col items-center main-content">
            <div className="register-container">
                <div className="flex flex-col content">
                    <WorkshopForm></WorkshopForm>
                </div>
            </div>
            <div className="w-full nav-line-2"></div>
            <Footer />
        </div>

    </Layout>)
}

export default RegisterPage